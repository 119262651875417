@use 'src/assets/style/_variables.scss' as var;

@mixin publication-type-catalogue {
  app-publication-type-catalogue {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    .page-content-container {
      position: relative;
    }
    .catalogue-toolbar {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      display: flex;
      gap: 0.5rem;
      background-color: aqua;
    }
    app-grid {
      height: 100%;
    }
  }
}
