@use 'src/assets/style/_variables.scss' as var;

@mixin bucket-details() {
  app-bucket-details {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    > app-grid {
      flex: 1;
    }

    .multiline-cell {
      white-space: break-spaces;
    }
  }
  app-bucket-dialog {
    min-width: 40vw;
    .row {
      gap: 10px;
    }
    .row > * {
      flex-grow: 1;
    }
  }
}
