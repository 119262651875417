@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin report-log-list() {
  app-report-log-list {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .download {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 2rem;
      gap: 1rem;

      .header,
      .status,
      .result {
        text-align: center;
      }

    }
  }
}
