@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin cc-framework() {
  app-cc-framework {
    display: flex;
    flex-direction: column;
    height: 100%;

    .cc-warning {
      padding: 0 1rem 1rem;
      color: #666;
      font-size: 0.8rem;
    }

    app-grid {
      flex: 1;
      height: 100%;
    }
  }
}
