@use 'src/assets/style/_variables.scss' as var;

@mixin publication-type-overview {
  app-publication-type-overview {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .tab-buttons {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    .publication-type-loading {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }
  app-publication-type-main-data {
    display: block;
    padding: 1rem;
    form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: "names hubs time-frame .";
      gap: 1rem;

      .field-names {
        grid-area: names;
        display: flex;
        flex-direction: column;
      }
      .field-hubs {
        grid-area: hubs;
        display: flex;
        flex-direction: column;

      }
      .field-advised {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .field-time-frame {
        grid-area: time-frame;
        display: flex;
        flex-direction: column;
      }
    }
  } /* app-publication-type-main-data */
  app-publication-type-internal-comms {
    display: block;
    padding: 1rem;
    form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto auto;
      grid-template-areas:
        "cc-emails banner subject";
      gap: 1rem;

      .cc-emails {
        grid-area: cc-emails;
        min-height: 50vh;
        .header {
          display: flex;
          flex-direction: row;
          .header-text {
            flex: 1;
          }
        }
      }

      .banner {
        grid-area: banner;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
      .subject {
        grid-area: subject;
      }
    } /* form */
  } /* app-publication-type-internal-comms */
  app-publication-type-subject {
    display: block;
    form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .input-wrapper {
        border-bottom: 1px solid black;
        padding: 1rem;
        &:has(.input:focus-visible) {
          background-color: rgba(200,200,200,0.1);
        }
      }
      .input:focus {
        outline: 0 solid transparent;
      }
      .input-wrapper.scrollable {
        overflow-x: auto;
        .input {
          text-wrap: nowrap;
        }
      }
      .example {
        .input-wrapper {
          border-bottom: none;
          background-color: rgba(200,200,200,0.1);
        }
      }
      .subject-field {
        display: inline;
        white-space: nowrap;
        background-color: rgba(100,100,100,0.2);
        border-radius: 8px;
        padding: 4px;
      }
    }
  } /* app-publication-type-subject */
  app-publication-type-send-controls {
    display: block;
    padding: 1rem;
    form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "send-approach sender salutation";
      gap: 1rem;

      .send-approach {
        grid-area: send-approach;
        display: flex;
        flex-direction: column;
      }
      .sender {
        grid-area: sender;
        display: flex;
        flex-direction: column;
      }
      .salutation {
        grid-area: salutation;
        display: flex;
        flex-direction: column;
      }
    }
  } /* app-publication-type-send-controls */
  app-publication-type-client-comms {
    display: block;
    padding: 1rem;
    flex: 1;
    >form {
      display: grid;
      height: 100%;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto auto auto 1fr;
      grid-template-areas:
        "channels   languages  subject"
        "product    languages  subject"
        "disclaimer disclaimer subject"
        "disclaimer disclaimer banner";
      gap: 1rem;

      .applicable-channels {
        grid-area: channels;
      }
      .languages {
        grid-area: languages;
      }
      .product-mode {
        grid-area: product;
      }
      .disclaimer {
        grid-area: disclaimer;
        display: flex;
        flex-direction: column;
        .disclaimer-label {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
        }
        .fr-box.fr-basic {
          flex: 1;
        }
      }
      .subject {
        grid-area: subject;
      }
    }
  } /* app-publication-type-client-comms */
  app-publication-type-monitoring {
    display: grid;
    padding: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "buckets . .";

    .buckets {
      grid-area: buckets;
      display: flex;
      flex-direction: column;
      position: relative;

      app-grid[tableid="pub-type-monitoring-buckets"] {
        height: 50vh;
      }

      .bucket-buttons {
        position: absolute;
        top: 4rem;
        left: 0;
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }

  } /* app-publication-type-monitoring */

  app-publication-type-open-close {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 30%;
  } /* app-publication-type-open-close */
  mat-option.bold {
    font-weight: bold;
  }
  .publication-type-col {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .publication-type-background {
    background-color: rgba(100,100,100,0.1);
    border-radius: 1rem;
  }
  ul.publication-type-checkbox-list {
    list-style: none;
    padding-inline-start: 0.5rem;
  }
}
