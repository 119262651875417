@use 'src/assets/style/_variables.scss' as var;

@mixin field-definition-details() {
  app-field-definition-details {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    &:first-child {
      border-bottom: 1px solid var.$color-grey-6;
    }

    > app-grid {
      flex: 1;
    }
  }
  app-field-definition-dialog {
    .row-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;

      mat-form-field {
        flex: 1;
      }

      .center-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 1rem;

        app-config-icon {
          color: var.$brand-color-primary;
        }
      }
    }
  }
}
