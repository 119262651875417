@use 'src/assets/style/_variables.scss' as var;

@mixin story-create-standard() {
  app-story-create-standard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .container {
      height: 100%;
    }

    mat-dialog-actions {
      padding: 1.25rem 1.5rem;
      border-top: 1px solid var.$color-grey-5;
    }
  }
}
