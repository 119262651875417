@use '@angular/material' as mat;
@use 'src/assets/style/_variables.scss' as var;
@use 'src/assets/style/_typography.scss' as typo;

@mixin tabs-picker() {
  app-tabs-picker {
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu-label {
      padding-right: 1rem;
    }
  }
  .tabs-picker-toolbar-parent {
    position: relative;
  }
  app-tabs-picker-toolbar {
    position: absolute;
    top: -3.25rem; // button height(2.25rem) + 1rem 'padding'; negative because above component
    right: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
}
