@use 'src/assets/style/_variables.scss' as var;

@mixin story-details-main-form() {
  app-story-details-main-form {
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .mat-button-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
