@use 'src/assets/style/_variables.scss' as var;

@mixin publication-type-create {
  app-publication-type-create {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
