
@mixin employee-publication-types() {
  app-employee-publication-types {
    .mat-mdc-tab-header {
      .mdc-tab {
        margin-top: -0.4rem;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;
        text-transform: inherit;
        letter-spacing: inherit;

        &--active {
          color: #1976d2 !important;
        }

        .mat-ripple, .mdc-tab__ripple {
          display: none;
        }
      }
    }

    .mat-slide-toggle.mat-disabled {
      opacity: 0.8;
    }
    mat-slide-toggle {
      padding-bottom: 0.4rem;
    }

    #explanation {
      margin-top: 1rem;
      color: #666;
    }
  }
}
