@use 'src/assets/style/_variables.scss' as var;
@use 'ag-grid-community/styles' as ag;

@mixin grid() {
  @include ag.grid-styles(
    (
      theme: ag-theme-aspark,
      extend-theme: material,
      grid-size: 5px,
      header-foreground-color: var.$brand-color-primary,
      font-family: #{'Poppins', 'sans-serif'},
      font-size: 1rem,
    )
  );

  app-grid {
    height: 100%; // WARNING: the parent's height must be defined or the grid might have no height
    ag-grid-angular {
      width: 100%;
      height: 100%;
    }
  }

  .ag-paging-page-size {
    display: none;
  }

  .dialog-filter {
    // Warning: This does not affect dialog-filter-height in card-body - see [.card-body .dialog-filter] bellow
    // Updates the grid height to compensate the filter-header component
    // Makes space for the grid-filter + its padding
    height: calc(100% - var.$grid-filter-height - 2*var.$grid-filter-padding);
    &.no-grid-filter-top-padding {
      height: calc(100% - var.$grid-filter-height - var.$grid-filter-padding);
    }
    &.no-grid-filter-padding {
      height: calc(100% - var.$grid-filter-height);
    }
    &.grid-filter-disabled {
      height: 100%;
    }
    /* we don't display the grid sidebar buttons since it will be controlled from an external component */
    .ag-side-buttons {
      display: none;
    }
    // allow side buttons to be displayed in inner details grid
    .ag-details-grid {
      .ag-side-buttons {
        display: block;
      }
    }
    // hides the overflown filter button in the header
    .ag-header.ag-header-allow-overflow .ag-header-row {
      overflow: hidden;
    }
  }

  .card-body .dialog-filter {
    // Updates the grid height to compensate the filter-header component
    // Makes space for the grid-filter + its padding
    height: calc(100% - var.$grid-filter-height - var.$grid-filter-padding);
    &.grid-filter-full-padding {
      height: calc(100% - var.$grid-filter-height - 2*var.$grid-filter-padding);
    }
    &.no-grid-filter-padding {
      height: calc(100% - var.$grid-filter-height);
    }
    &.grid-filter-disabled {
      height: 100%;
    }
    /* we don't display the grid sidebar buttons since it will be controlled from an external component */
    .ag-side-buttons {
      display: none;
    }
    // allow side buttons to be displayed in inner details grid
    .ag-details-grid {
      .ag-side-buttons {
        display: block;
      }
    }
    // hides the overflown filter button in the header
    .ag-header.ag-header-allow-overflow .ag-header-row {
      overflow: hidden;
    }
  }

  // Warning: This does not affect grid-filter-wrapper-padding in card-body - see [.card-body app-grid-filter] bellow
  app-grid-filter {
    .grid-filter-wrapper {
      padding-top: var.$grid-filter-padding;
      padding-bottom: var.$grid-filter-padding;
    }
    &.no-grid-filter-top-padding {
      .grid-filter-wrapper {
        padding-top: 0;
      }
    }
    &.no-grid-filter-padding {
      .grid-filter-wrapper {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  // If grid-filter-wrapper is in a card, we let the card handle the padding by default
  // Default padding for cards it thus top and right 0
  .card-body app-grid-filter {
    .grid-filter-wrapper {
      padding-top: 0;
      padding-right: 0;
    }
    // only has an effect in cards
    &.grid-filter-full-padding {
      .grid-filter-wrapper {
        padding-top: var.$grid-filter-padding;
      }
    }
    &.no-grid-filter-padding {
      .grid-filter-wrapper {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .ag-theme-aspark {
    .ag-header {
      background-color: transparent;
    }
    .ag-header-row {
      font-size: var(--ag-font-size);
    }
    .ag-row {
      font-size: calc(var(--ag-font-size) - 2px)
    }
    .ag-sort-indicator-container {
      font-size: calc(var(--ag-font-size) - 2px);
      font-weight: 400;
    }
    .ag-root-wrapper {
      border: none;

      .ag-paging-panel {
        white-space: nowrap;
      }
    }
    .mat-button-wrapper {
      margin-top: 1px;
    }
    .mat-icon-button {
      mat-icon {
        line-height: inherit;
      }
    }

    .ag-cell-edit-wrapper, .ag-list-item {
      font-size: calc(var(--ag-font-size) - 2px); // same as ag-row
      min-height: var(--ag-row-height);
    }

    .ag-list-item {
      min-height: calc(var(--ag-row-height) + 6px);
      align-items: center;
    }

    .ag-row.ag-row-warn {
      background-color: var.$state-color-error;
    }

    .ag-row-odd {
      background-color: var.$color-grey-6;
    }

    .ag-row {
      border-bottom: none;
      .ag-details-row {
        padding: 1rem 1rem 1rem 3rem;

        .ag-details-grid {
          border: solid 1px var.$color-grey-5;

          .ag-header-viewport {
            background-color: var.$color-grey-6;
          }

          div.ag-cell {
            display: flex;
            align-items: center;
          }
        }
      }

      &.ag-row-selected {
        background-color: var.$color-light-blue;
      }
      &.ag-row-hover {
        background-color: var.$color-light-blue;
      }

      &.ag-row-hover::before {
        background-image: unset;
        background-color: transparent;
      }

      &.ag-row-selected::before {
        background-image: unset;
        background-color: transparent;
      }

      &.ag-row-disabled {
        color: var.$color-grey-3;
        background-color: var.$color-light-green;
      }

      &.ag-row-hover.ag-row-disabled,
      &.ag-row-selected.ag-row-disabled {
        background-color: var.$color-light-green;
      }

      &.ag-row-hover.ag-row-warn,
      &.ag-row-selected.ag-row-warn {
        &::before {
          background-color: var.$state-color-error;
        }
      }
    }

    .ag-header-cell,
    .ag-cell {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .ag-cell-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
    }

    .ag-cell {
      .grid__icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .mat-icon-button {
          width: auto;
          height: auto;
          padding: 0.5rem;
        }
      }

      .grid-custom-cell {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      app-config-icon {
        display: flex;
        align-items: center;
        width: 21px;
        font-size: 21px;
        color: var.$brand-color-primary;

        mat-icon {
          font-size: inherit;
        }
      }

      &[col-id='icon-button-view'] {
        display: flex;
        justify-content: center;
      }

      &[col-id='icon-button-delete'],
      &.danger {
        app-config-icon {
          color: var.$state-color-error;
        }
      }

      &.hot {
        app-config-icon {
          color: var.$color-black-1;
        }
      }

      .edit-cell-icon {
        display: none;
      }

      &.editable-cell {
        cursor: pointer;
        .edit-cell-icon {
          display: flex;
          font-size: 1.33rem;
          color: var.$brand-color-primary;
        }
      }

      &.ag-cell-inline-editing {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: 100%;
      }

      .grid-cell-custom-content {
        display: flex;
      }

      .grid-cell-action-language {
        display: flex;

        app-config-icon[iconIdentifier='warning'] {
          color: var.$state-color-warning;
          margin-left: auto;
        }
        app-config-icon[iconIdentifier='error'] {
          color: var.$state-color-error;
          margin-left: auto;
        }
      }

      .grid-cell-dialog-content {
        display: flex;
      }
    }

    .cell-align-right {
      text-align: right;
    }

    .ag-header-cell[role='columnheader'] {
      .ag-icon-filter {
        visibility: hidden;
      }

      .ag-icon-asc,
      .ag-icon-desc {
        color: var.$brand-color-primary;
      }
    }

    .ag-set-filter-list {
      min-height: 150px;
    }

    .filter-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &.bottom-bar-padding {
      padding-bottom: 2.125rem;
    }

    div[col-id='checkboxes'] {
      .ag-cell-wrapper {
        min-width: 3.3rem;
      }

      .ag-cell-value {
        height: 100%;

        & > ng-component {
          height: 100%;
          display: flex;
          align-items: center;

          mat-checkbox {
            padding-left: 1rem;
          }
        }
      }
    }
    .ag-floating-filter-button-button:hover {
      cursor: pointer;
    }

    .ag-header-cell-label {
      justify-content: space-between;
    }
    // END THEME
  }

  // SIDEBAR
  .ag-column-select-list {
    .ag-column-select-column {
      gap: 0.4rem;
    }
  }

  .mat-mdc-menu-panel.grid-more-actions-menu {
    min-width: unset;
    max-width: unset;
    margin-bottom: 7px; // aligns menu with gird border

    .mat-mdc-menu-content {
      display: flex;
      gap: 1rem;
      align-items: center;
      flex-direction: row;
      padding: 0.5rem;

      app-config-icon {
        width: 1.5rem;
        font-size: 1.5rem;
        position: relative;
        color: var.$brand-color-primary;
        cursor: pointer;
      }
    }
  }

  .grid-basic-toolbar {
    display: flex;
    gap: 1rem;
    flex: 1;
    align-items: center;

    // Old approach, if no pagination is used
    &.no-pagination {
      // computed from: ag-paging-panel-height w/o border (34) in rem
      margin-top: -2.125rem;
      // padding computed as: (ag-paging-panel-height - grid-basic-toolbar-height)/2
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      // ag-paging-panel element margin (15px)
      margin-left: 0.9375rem;
    }

    .large-actions-container,
    .small-actions-container {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    app-config-icon {
      width: 1.5rem;
      font-size: 1.5rem;
      z-index: 1;
      position: relative;
      color: var.$brand-color-primary;
      cursor: pointer;
    }
    .mat-mdc-select {
      min-width: 5rem;
      max-width: 5rem;
      font-size: 0.875rem;
      margin-left: auto;
    }

    .selection-container {
      display: flex;
      gap: 0.5rem;
      z-index: 1;
      font-size: 0.875rem;

      .count-label {
        display: inline-flex;
        align-items: center;
        height: 1.5rem; // to align with icons and page selection
        white-space: nowrap;
      }

      .icon-text-btn {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        color: var.$brand-color-primary;;
        cursor: pointer;

        &.error-btn {
          color: var.$state-color-error;

          app-config-icon {
            width: 1.25rem;
            margin: unset;
            color: var.$state-color-error;
          }
        }
      }
    }
  }

  .custom-column-side-panel {
    position: fixed;
    --ag-control-panel-background-color: lightgray;
  }

  .ag-tooltip {
    // copied from material css
    word-break: normal;
    overflow-wrap: anywhere;
    padding: 4px 8px;
    min-width: 40px;
    max-width: 200px;
    min-height: 24px;
    max-height: 40vh;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    will-change: transform,opacity;
    background-color: var(--mdc-plain-tooltip-container-color, var(--mat-app-inverse-surface));
    color: var(--mdc-plain-tooltip-supporting-text-color, var(--mat-app-inverse-on-surface));
    border-radius: var(--mdc-plain-tooltip-container-shape, var(--mat-app-corner-extra-small));
    font-family: var(--mdc-plain-tooltip-supporting-text-font, var(--mat-app-body-small-font));
    font-size: var(--mdc-plain-tooltip-supporting-text-size, var(--mat-app-body-small-size));
    font-weight: var(--mdc-plain-tooltip-supporting-text-weight, var(--mat-app-body-small-weight));
    line-height: var(--mdc-plain-tooltip-supporting-text-line-height, var(--mat-app-body-small-line-height));
    letter-spacing: var(--mdc-plain-tooltip-supporting-text-tracking, var(--mat-app-body-small-tracking));
  }

  .modal-content {
    app-grid {
      display: flex;
      flex-direction: column;
    }
  }
}
